import React, { Component } from "react";
import moment from "moment";
import swapIcon from "../../../images/swap-icon.svg";
import less from "./search.module.scss";
import {
  Button,
  DatePicker,
  Select,
  message,
  Modal,
  Input,
  AutoComplete
} from "antd";
import Fetch from "../../../utils/fetch";
import { ThunderboltFilled } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const { Option } = Select;

class OperatorSearch extends Component {
  state = {
    showDatePicker: false,
    hideDate: false,
    raisedBy: "all",
    fromCity: "",
    interValue: {},
    intraValue: {},
    toCity: "",
    inputValue: "",
    cities: [],
    stations: [],
    showCreateModal: false,
    assetType: "CAB",
    searchBy: "tripId",
    showErrorModal: false,
    showConfirmationModal: false,
    fromDate: moment()
      .startOf("day")
      .valueOf(),
    toDate: moment()
      .endOf("day")
      .valueOf(),
    intraStations: []
  };

  async componentDidMount() {
    try {
      const { cities, cabOperators } = this.props;
      // const activeCities = cities.filter(city => city.status == "ACTIVE");
      this.setState({ cities, cabOperators });
    } catch (err) {
      message.error(err);
    }
  }

  showResult = async () => {
    const {
      raisedBy,
      complainStatus,
      fromDate,
      toDate,
      time,
      searchedComplain
    } = this.state;
    let body = {};
    this.props.showLoader();
    if (raisedBy) {
      body.raisedBy = raisedBy;
    }
    if (
      searchedComplain &&
      Array.isArray(searchedComplain) &&
      searchedComplain.length > 0
    ) {
      body._id = searchedComplain[0]._id;
      body.raisedBy = searchedComplain[0].type;
    }
    if (complainStatus) {
      body.complainStatus = complainStatus;
    }
    if (fromDate && toDate) {
      body.fromDate = fromDate.valueOf();
      body.toDate = toDate.valueOf();
    }
    if ((fromDate && !toDate) || (toDate && !fromDate)) {
      message.error("please select both date");
      return;
    }
    if (Number.isInteger(time)) {
      body.time = time;
    }
    const url = `/gds/outbound/searchCustomComplain`;
    const options = {
      method: "post",
      data: {
        body
      }
    };
    const response = await Fetch(url, options);
    if (response && Array.isArray) {
      this.props.onSearch(response);
    } else {
      this.props.onSearch(null);
    }
  };

  handleSwap = () => {
    const { fromCity, toCity } = this.state;

    this.setState({ fromCity: toCity, toCity: fromCity });
  };

  handleSpecialCharacters = event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ searchValue: newCheck });
  };

  handleChange = async (key, value, data) => {
    if (key === "fromCity") {
      this.setState({
        fromCity: value,
        fromCityId: data.key
      });
    } else if (key === "toCity") {
      this.setState({
        toCity: value,
        toCityId: data.key
      });
    } else if (key === "cabOperatorId") {
      this.setState({
        cabOperator: value,
        cabOperatorId: data.key
      });
    } else this.setState({ [key]: value });
  };

  search = async () => {
    try {
      const {
        fromCityId,
        toCityId,
        searchValue,
        cabOperatorId,
        searchBy,
        fromDate,
        toDate
      } = this.state;

      const fromDateMillis = fromDate;
      const toDateMillis = toDate;
      const body = {
        fromCityId,
        toCityId,
        searchValue,
        cabOperatorId,
        searchBy,
        fromDateMillis,
        toDateMillis
      };
      const url = `/cabOperator/getSpecificTrip`;
      const options = {
        method: "post",
        data: { details: body }
      };
      const response = await Fetch(url, options);
      if (response) {
        this.props.onSearch(response);
      } else {
        this.props.onSearch([]);
      }
    } catch (err) {
      console.log(err);
      this.props.onSearch([]);
    }
  };
  citySearch = () => {
    const { fromCityId, toCityId } = this.state;
    const body = {
      fromCityId,
      toCityId
    };
    this.search(body);
  };

  typeSearch = () => {
    const { searchValue, cabOperatorId, searchBy } = this.state;
    const body = {
      searchBy,
      searchValue,
      cabOperatorId
    };
    this.search(body);
  };

  onTimeRangeSelect = value => {
    const [startTime, endTime] = value;
    const fromDate = moment(startTime)
      .startOf("day")
      .valueOf();
    const toDate = moment(endTime)
      .endOf("day")
      .valueOf();
    this.setState({ fromDate, toDate });
  };

  dateSearch = () => {
    const { fromDate, toDate } = this.state;
    const fromDateMillis = fromDate;
    const toDateMillis = toDate;

    const body = {
      fromDateMillis,
      toDateMillis
    };
    this.search(body);
  };

  render() {
    const {
      fromDate,
      toDate,
      cities,
      fromCity,
      searchBy,
      toCity,
      searchValue,
      cabOperators = [],
      cabOperator
    } = this.state;
    return (
      <>
        <div className={less.search}>
          <div className={less.citySearch}>
            <Select
              showSearch
              placeholder="From City"
              style={{ width: 150 }}
              onChange={(value, key) =>
                this.handleChange("fromCity", value, key)
              }
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map(city => (
                <Option value={city.name} key={city._id}>
                  {city.name}
                </Option>
              ))}
            </Select>
            <button className="swapButton" onClick={this.handleSwap}>
              <img src={swapIcon} alt="swapicon" />
            </button>
            <Select
              placeholder="To City"
              showSearch
              style={{ width: 150 }}
              onChange={(value, key) => this.handleChange("toCity", value, key)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map(city => (
                <Option value={city.name} key={city._id}>
                  {city.name}
                </Option>
              ))}
            </Select>
            <button className={less.searchBtn} onClick={this.search}>
              <span>Search</span>
            </button>
          </div>

          <div className={less.otherSearch}>
            <Select
              style={{ width: 200, margin: "0 0.2rem" }}
              placeholder="Select"
              value={searchBy}
              onChange={value => this.setState({ searchBy: value })}
            >
              <Option value="tripId">By Trip ID</Option>
              <Option value="operator">By Operator</Option>
            </Select>

            {searchBy === "tripId" ? (
              <Input
                type="text"
                placeholder="Search"
                style={{ width: 200, margin: "0 0.2rem" }}
                value={searchValue}
                onChange={this.handleSpecialCharacters}
              />
            ) : (
              <Select
                showSearch
                style={{ width: 200 }}
                onChange={(value, data) =>
                  this.handleChange("cabOperatorId", value, data)
                }
                value={cabOperator}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cabOperators.map(operator => (
                  <Option value={operator.value} key={operator._id}>
                    {operator.value}
                  </Option>
                ))}
              </Select>
            )}
            <button className={less.searchBtn} onClick={this.search}>
              <span>Search</span>
            </button>
          </div>

          <div className={less.dateSelect}>
            <RangePicker
              value={[moment(fromDate), moment(toDate)]}
              format="YYYY-MM-DD"
              onChange={this.onTimeRangeSelect}
            />
          </div>

          <button className={less.Btn} onClick={this.search}>
            <span>Search / Refresh</span>
          </button>

          <button
            className={less.searchBtn}
            onClick={this.props.showCreateModal}
          >
            <span>+ Create a trip</span>
          </button>
        </div>
      </>
    );
  }
}

export default OperatorSearch;
