import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { googleMapsApiKey } from "./config";

// Function to dynamically load the Google Maps script
const loadGoogleMapsScript = apiKey => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=geometry,drawing`;
  script.async = true;

  script.onload = () => {
    console.log("Google Maps API loaded successfully.");
    ReactDOM.render(<App />, document.getElementById("root"));
  };

  script.onerror = error => {
    console.error("Error loading Google Maps API:", error);
  };

  document.head.appendChild(script);
};

// Load the Google Maps API dynamically using the key from the config
loadGoogleMapsScript(googleMapsApiKey);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
