import React, { Component } from "react";
import { Select, Table, DatePicker, Modal, message, Tooltip } from "antd";

import Loader from "../../loader";
import Fetch from "../../../utils/fetch";
import Search from "../operatorSearch";
import moment from "moment";
import less from "./trips.module.scss";
import jwt from "jsonwebtoken";
import { getSeatNumberFromTripChart } from "../../../utils/common";

const { Option } = Select;

const columns = [
  {
    title: "Route",
    dataIndex: "route",
    key: "route",
    width: "10%",
    render: ({ fromCity, toCity }) => (
      <>
        <span>{fromCity}</span>
        <br />
        <span>{toCity}</span>
      </>
    )
  },
  {
    title: "Trip ID",
    dataIndex: "tripId",
    key: "tripId",
    width: "15%",
    render: ({ value, copy }) => (
      <div className={less.tripTable}>
        <span>{value}</span>
        <img
          onClick={() => copy(value)}
          src="https://d1flzashw70bti.cloudfront.net/original/images/tarcer/copy.svg"
          alt="copy"
        />
      </div>
    )
  },
  {
    title: "Start Time & Date",
    dataIndex: "date",
    key: "date",
    width: "10%"
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "10%"
  },
  {
    title: "Seats",
    dataIndex: "seat",
    key: "seat",
    width: "10%",
    render: ({ value }) => (
      <div>
        <span>{value}</span>
      </div>
    )
  },
  Table.EXPAND_COLUMN,

  {
    title: "Asset Type",
    dataIndex: "assetType",
    key: "assetType",
    width: "10%"
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
    width: "10%"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ value }) => (
      <div className={less.statusDiv}>
        <div
          className={
            value === "ACTIVE" ? less.statusActive : less.statusInActive
          }
        ></div>
        <span>{value}</span>
      </div>
    )
  },
  {
    title: "Assign",
    dataIndex: "assign",
    key: "assign",
    width: "15%",
    render: ({
      isDriverDetailAvailable,
      assign,
      _id,
      isAssigned,
      driverDetails
    }) => (
      <div className={less.assignTable}>
        {isAssigned ? (
          <div className={less.isAssigned}>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/accept.svg"
              alt="accept"
            />
            <span>Assigned</span>
            {isDriverDetailAvailable ? (
              <Tooltip
                title={driverDetails}
                overlayStyle={{
                  backgroundColor: "transparent", // Make background transparent
                  borderRadius: "8px", // Add border-radius
                  border: "1px solid rgba(0, 0, 0, 0.1)" // Optional: add a subtle border if you want
                }}
                trigger={"click"}
              >
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/tooltip.svg"
                  alt="tooltip"
                />
              </Tooltip>
            ) : null}
          </div>
        ) : (
          <div className={less.notAssigned} onClick={() => assign(_id)}>
            {" "}
            <span>Assign</span>
          </div>
        )}
      </div>
    )
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
    render: ({ canEdit, edit, logs, _id }) => (
      <div className={less.editTable}>
        {canEdit ? (
          <div onClick={() => edit(_id)}>
            <span>Edit</span>
          </div>
        ) : null}

        <div onClick={() => logs(_id)}>
          <span>Logs</span>
        </div>
      </div>
    )
  }
];

class OperatorCabTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      operatorTableData: [],
      expandedRowsData: {},
      showEditModal: false,
      showCreateModal: false,
      editData: {},
      showLogModal: false,
      expandedRowKeys: [],
      cities: []
    };
  }

  async componentDidMount() {
    try {
      const response = await this.fetchData();
      const cabOperators = await this.fetchOperators();
      const assetTypeValues = await this.fetchAssetType();

      const { cities } = this.props;
      const activeCities = cities.filter(city => city.status == "ACTIVE");
      this.setState({
        cabOperators,
        operatorTableData: response.data,
        operatorData: response.allResponse,
        loading: false,
        cities: activeCities,
        assetTypeValues
      });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }
  fetchData = async () => {
    try {
      const url = `/cabOperator/trips`;

      const options = {
        method: "get"
      };
      let allResponse = await Fetch(url, options);
      let data = [];

      if (Array.isArray(allResponse) && allResponse.length > 0) {
        data = this.formatData(allResponse);
      } else {
        allResponse = [];
      }

      return { data, allResponse };
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  sortData = data => {
    const currentTime = Date.now();

    data.sort((a, b) => {
      if (a.status === "ACTIVE" && b.status !== "ACTIVE") {
        return -1;
      }
      if (a.status !== "ACTIVE" && b.status === "ACTIVE") {
        return 1;
      }

      if (a.startTime > currentTime && b.startTime <= currentTime) {
        return -1;
      }
      if (a.startTime <= currentTime && b.startTime > currentTime) {
        return 1;
      }

      return a.startTime - b.startTime;
    });

    return data;
  };

  countTotalSeatsInTrip(trip) {
    let totalSeats = 0;
    trip.bookings.forEach(booking => {
      totalSeats += booking.tripChart.length;
    });

    return totalSeats;
  }

  formatData = values => {
    const sortedData = this.sortData(values);
    let tableData = sortedData.map(value => {
      let canEdit = Date.now() <= value.startTime;
      let data = {
        key: value._id,
        route: {
          fromCity: value.fromCity.name,
          toCity: value.toCity.name
        },
        tripId: { value: value.groupCode, copy: this.copyData },
        date: moment(value.startTime).format("h:mm a, DD MMM YY"),
        type: value.isIntraCity ? "Intra" : "Inter",
        seat: { value: this.countTotalSeatsInTrip(value) },
        assetType: value.assetType,
        operator: value.cabOperator ? value.cabOperator.name : null,
        status: {
          value: value.status
        },
        assign: {
          isDriverDetailAvailable: !!value.driverDetails,
          assign: this.assignToOperator,
          isAssigned: value.assignedToOperator,
          _id: value._id,
          driverDetails: this.getDriverDetails(
            value.driverDetails,
            value.vehicleDetails
          )
        },
        actions: {
          canEdit,
          _id: value._id,
          edit: this.editData,
          logs: this.showLogs
        },
        bookings: value.bookings
      };
      return data;
    });
    return tableData;
  };

  handleExpandedRowRender = record => {
    const data = this.listData(record.bookings);
    return data;
  };

  handleRowExpand = (expanded, record) => {
    this.setState(prevState => {
      const { expandedRowKeys } = prevState;
      if (expanded) {
        return { expandedRowKeys: [...expandedRowKeys, record.key] };
      } else {
        return {
          expandedRowKeys: expandedRowKeys.filter(key => key !== record.key)
        };
      }
    });
  };

  listData = bookings => {
    let x = 1;

    if (!bookings) {
      return <span>No Booking found</span>;
    } else
      return bookings.map((booking, index) => (
        <div key={index} className={less.listData}>
          <div>
            <span>{x++}</span>
          </div>
          <div className={less.stationDetail}>
            <div>
              <span>PNR : </span>
              <span>{booking.connectingBookingCode}</span>
            </div>
            <div>
              <span>Leg PNR : </span>
              <span>{booking.bookingCode}</span>
            </div>
          </div>
          <div>
            <span>Seats : </span>
            <span>{getSeatNumberFromTripChart(booking.tripChart) || "NA"}</span>
          </div>
          <div className={less.stationDetail}>
            <div>
              <span>Pickup Station : </span>
              <span>
                {booking.fromStation ? booking.fromStation.name : null}
              </span>
            </div>
            <div>
              <span>Drop Station : </span>
              <span>{booking.toStation ? booking.toStation.name : null}</span>
            </div>
          </div>
          <div className={less.status}>
            <span
              style={{
                color: booking.status == "CANCELLED" ? "#ED1E21" : "#69AC39"
              }}
            >
              {booking.status}{" "}
            </span>
          </div>
          <div className={less.stationDetail}>
            <div>
              <span>Pickup Time : </span>
              <span>
                {moment(parseInt(booking.fromStationDate)).format("h:mm a")}
              </span>
            </div>
            <div>
              <span>Drop Time : </span>
              <span>
                {" "}
                {moment(parseInt(booking.toStationDate)).format("h:mm a")}
              </span>
            </div>
          </div>
          <div className={less.remove}>
            <span
              onClick={() =>
                this.removeTrip(booking._id, booking.cabBookingGroupId)
              }
            >
              Remove
            </span>
          </div>
        </div>
      ));
  };

  removeTrip = async (bookingId, cabGroupId) => {
    const { operatorData } = this.state;
    let body = {
      cabBookingGroupId: cabGroupId,
      bookingIdsToRemove: [bookingId]
    };
    const url = `/cabOperator/removeTripToBooking`;

    const options = {
      method: "post",
      data: { details: body }
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response)) {
      const updatedOperatorData = operatorData.map(operator => {
        const updatedResponse = response.find(
          item => item._id === operator._id
        );

        if (updatedResponse) {
          return updatedResponse; // replace operator with updatedResponse
        }
        return operator;
      });

      const data = this.formatData(updatedOperatorData);

      this.setState({
        operatorTableData: data,
        operatorData: updatedOperatorData
      });
      message.success("Booking is Removed");
    } else {
      message.error(response);
    }
  };

  fetchAssetType = async () => {
    const url = `/cabOperator/getAssetType`;

    const options = {
      method: "get"
    };
    const response = await Fetch(url, options);
    if (response) {
      return response;
    } else {
      return [];
    }
  };

  showCreateTripModal = async () => {
    this.setState({
      showCreateModal: true,
      showErrowMessage: false,
      tripType: "inter",
      cabOperatorId: undefined,
      interValue: {
        startTime: moment()
      },
      cabOperator: null,
      intraValue: {
        startTime: moment()
      }
    });
  };

  getDriverDetails = (data, rcDetails) => {
    return (
      <div className={less.driverDetail}>
        <div>
          <div>
            <span>RC</span>
            <span>{rcDetails && rcDetails.rc ? rcDetails.rc : null}</span>
          </div>
          <img
            onClick={() => this.copyData(rcDetails.rc)}
            src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/copy.svg"
            alt="copy"
          />
        </div>
        <div>
          <div>
            <span>Mobile</span>
            <span>{data && data.mobileNo ? data.mobileNo : null}</span>
          </div>
          <img
            onClick={() => this.copyData(data.mobileNo)}
            src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/copy.svg"
            alt="copy"
          />
        </div>
        <div>
          <div>
            <span>Name</span>
            <span>{data && data.name ? data.name : null}</span>
          </div>
          <img
            onClick={() => this.copyData(data.name)}
            src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/copy.svg"
            alt="copy"
          />
        </div>
      </div>
    );
  };

  handleChange = async (key, value, data) => {
    let stations = [];
    if (key === "tripType" && value === "intra") {
      stations = await this.fetchStations();
    }
    if (key === "cabOperatorId") {
      this.setState({ [key]: data.key, cabOperator: value });
    } else this.setState({ [key]: value, stations });
  };

  fetchStations = async () => {
    try {
      const url = `/bus-tracker/getStationsCache`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  fetchOperators = async () => {
    const url = `/cabOperator/getOperator`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    return response;
  };
  createCabGroup = async () => {
    const {
      cabOperatorId,
      assetType,
      interValue,
      intraValue,
      tripType
    } = this.state;
    let details = {
      cabOperatorId,
      assetType
    };
    if (tripType == "inter") {
      if (
        !cabOperatorId ||
        !assetType ||
        !interValue.toCityId ||
        !interValue.fromCityId ||
        !interValue.startTime
      ) {
        this.setState({ showErrowMessage: true });
        return;
      }
      details = {
        ...details,
        ...interValue
      };
    } else {
      details = {
        ...details,
        ...intraValue
      };

      details = {
        cabOperatorId: details.cabOperatorId,
        assetType: details.assetType,
        fromStationId: details.fromStationId,
        toStationId: details.toStationId,
        startTime: details.startTime,
        isIntraCity: true,
        fromCityId: details.cityId,
        toCityId: details.cityId
      };

      if (
        !cabOperatorId ||
        !assetType ||
        !details.fromStationId ||
        !details.toStationId ||
        !details.fromCityId ||
        !details.startTime
      ) {
        this.setState({ showErrowMessage: true });
        return;
      }
    }
    const date = new Date(details.startTime);
    const millis = date.getTime();
    details = {
      ...details,
      startTime: millis
    };

    const url = `/cabOperator/createCabGroup`;

    const options = {
      method: "post",
      data: { details }
    };

    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      this.setState({
        assetType: "",
        showErrowMessage: false,
        showCreateModal: false,
        showConfirmationModal: true
      });
    } else {
      this.setState({
        assetType: "",
        showErrowMessage: false,
        showErrowMessage: true,
        showCreateModal: false,
        showErrorModal: true
      });
    }
  };

  setInterCityValues = (key, value, data) => {
    let { interValue = {} } = this.state;
    let newInterValue = interValue;

    if (key === "toCityId") {
      if (data.key == interValue.fromCityId) {
        message.error("Both City can't be same");
        return;
      }
      newInterValue = {
        ...interValue,
        toCity: value,
        toCityId: data.key
      };
      this.setState({ interValue: newInterValue });
    } else if (key === "fromCityId") {
      newInterValue = {
        ...interValue,
        fromCity: value,
        fromCityId: data.key
      };
      this.setState({ interValue: newInterValue });
    } else {
      interValue[key] = value;
      this.setState({ interValue });
    }
  };

  setIntraCityValues = (key, value, data) => {
    let { intraValue = {}, stations } = this.state;
    let newIntraValue = intraValue;
    if (key === "city") {
      let cityId = data.key;
      let intraStations = stations.filter(station => station.cityId == cityId);
      newIntraValue = {
        ...newIntraValue,
        cityId,
        fromStationName: undefined,
        toStationName: undefined,
        city: value
      };
      this.setState({ intraValue: newIntraValue, intraStations });
    } else if (key === "fromStationId") {
      newIntraValue = {
        ...intraValue,
        fromStationName: value,
        fromStationId: data.key
      };
      this.setState({ intraValue: newIntraValue });
    } else if (key === "toStationId") {
      newIntraValue = {
        ...newIntraValue,
        toStationName: value,
        toStationId: data.key
      };
      this.setState({ intraValue: newIntraValue });
    } else {
      intraValue[key] = value;

      this.setState({ intraValue });
    }
  };

  onOk = value => {
    const { tripType } = this.state;
    const date = new Date(value);
    const millis = date.getTime();
    if (tripType == "inter") {
      this.setInterCityValues("startTime", millis);
    } else {
      this.setIntraCityValues("startTime", millis);
    }
  };

  editValue = (key, value, data) => {
    const { newEditValues = {}, stations } = this.state;
    let edittedData = newEditValues;
    if (key === "fromCityId") {
      edittedData = {
        ...edittedData,
        edittedFromCity: value,
        edittedFromCityId: data.key
      };
    } else if (key === "toCityId") {
      edittedData = {
        ...edittedData,
        edittedToCity: value,
        edittedToCityId: data.key
      };
    } else if (key === "city") {
      let cityId = data.key;
      let intraStations = stations.filter(station => station.cityId == cityId);
      this.setState({ intraStations });

      edittedData = {
        ...edittedData,
        edittedFromStation: undefined,
        edittedToStation: undefined,
        edittedCity: value,
        edittedCityId: data.key
      };
    } else if (key === "fromStationId") {
      edittedData = {
        ...edittedData,
        edittedFromStation: value,
        edittedFromStationId: data.key
      };
    } else if (key === "toStationId") {
      edittedData = {
        ...edittedData,
        edittedToStation: value,
        edittedToStationId: data.key
      };
    } else if (key === "assetType") {
      edittedData = {
        ...edittedData,
        edittedAssetType: value
      };
    } else if (key === "status") {
      edittedData = {
        ...edittedData,
        edittedStatus: value
      };
    } else if (key === "cabOperatorId") {
      edittedData = {
        ...edittedData,
        edittedCabOperator: value,
        edittedCabOperatorId: data.key
      };
    } else if (key === "startTime") {
      edittedData = {
        ...edittedData,
        edittedStartTime: value
      };
    }
    this.setState({ newEditValues: edittedData });
  };

  onEditOk = value => {
    const date = new Date(value);
    const millis = date.getTime();
    this.editValue("startTime", millis);
  };

  updateGroup = async () => {
    const { newEditValues = {}, editData, operatorData } = this.state;
    let remark = editData.remark ? `${editData.remark} ` : "";
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const {
      edittedStartTime,
      edittedCabOperatorId,
      edittedCabOperator,
      edittedStatus,
      edittedAssetType,
      edittedToStationId,
      edittedFromStationId,
      edittedCityId,
      edittedToCityId,
      edittedFromCityId,
      edittedToStation,
      edittedFromStation,
      edittedToCity,
      edittedFromCity,
      edittedCity
    } = newEditValues;
    if (Object.keys(newEditValues).length === 0) {
      message.error("Nothing To Update");
      this.setState({
        showEditModal: false,
        newEditValues: {}
      });
      return;
    }
    let bodyData = { remark };
    if (edittedCity && (!edittedFromStation || !edittedToStation)) {
      this.setState({ showErrowMessage: true });
      return;
    }
    if (edittedStartTime && edittedStartTime !== editData.startTime) {
      bodyData.remark += `||| startTime is Updated from ${moment(
        editData.startTime
      ).format("h:mm a, DD MMM YY")} to ${moment(edittedStartTime).format(
        "h:mm a, DD MMM YY"
      )} by ${decodedToken.name} at ${moment().format("LLL")}  `;
      bodyData = { ...bodyData, startTime: edittedStartTime };
    }
    if (
      edittedCabOperatorId &&
      edittedCabOperatorId !== editData.cabOperator._id
    ) {
      bodyData.remark += `||| cab Operator is Updated from ${
        editData.cabOperator.name
      } to ${edittedCabOperator} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = { ...bodyData, cabOperatorId: edittedCabOperatorId };
    }
    if (edittedStatus && edittedStatus !== editData.status) {
      bodyData.remark += `||| status is Updated from ${
        editData.status
      } to ${edittedStatus} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = { ...bodyData, status: edittedStatus };
    }
    if (edittedAssetType && edittedAssetType !== editData.assetType) {
      bodyData.remark += `||| assetType is Updated from ${
        editData.assetType
      } to ${edittedAssetType} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = { ...bodyData, assetType: edittedAssetType };
    }
    if (edittedToStationId && edittedToStation !== editData.toStation.name) {
      bodyData.remark += `||| To Station is Updated from ${
        editData.toStation.name
      } to ${edittedToStation} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = { ...bodyData, toStationId: edittedToStationId };
    }
    if (
      edittedFromStationId &&
      edittedFromStation !== editData.fromStation.name
    ) {
      bodyData.remark += `||| From Station is Updated from ${
        editData.fromStation.name
      } to ${edittedFromStation} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = { ...bodyData, fromStationId: edittedFromStationId };
    }
    if (edittedCityId && edittedCity !== editData.fromCity.name) {
      bodyData.remark += `||| City is Updated from ${
        editData.fromCity.name
      } to ${edittedCity} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = {
        ...bodyData,
        fromCityId: edittedCityId,
        toCityId: edittedCityId
      };
    }
    if (edittedToCityId && edittedToCity !== editData.toCity.name) {
      bodyData.remark += `||| To City is Updated from ${
        editData.toCity.name
      } to ${edittedToCity} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = { ...bodyData, toCityId: edittedToCityId };
    }
    if (edittedFromCityId && edittedFromCity !== editData.fromCity.name) {
      bodyData.remark += `||| From City is Updated from ${
        editData.fromCity.name
      } to ${edittedFromCity} by ${decodedToken.name} at ${moment().format(
        "LLL"
      )}  `;
      bodyData = { ...bodyData, fromCityId: edittedFromCityId };
    }

    if (Object.keys(bodyData).length === 1) {
      message.error("Nothing To Update");
      this.setState({
        showEditModal: false,
        newEditValues: {}
      });
      return;
    }
    if (editData.isIntraCity) {
      bodyData = {
        ...bodyData,
        isIntraCity: true
      };
    }
    if (editData.assignedToOperator) {
      bodyData = {
        ...bodyData,
        triggerCommuntication: true
      };
    }

    const url = `/cabOperator/updateGroup`;

    const options = {
      method: "patch",
      data: { updateBody: bodyData, _id: editData._id }
    };

    const response = await Fetch(url, options);

    if (Array.isArray(response) && response.length > 0) {
      const updatedOperatorData = operatorData.map(operator => {
        const updatedResponse = response.find(
          item => item._id === operator._id
        );

        if (updatedResponse) {
          return { ...operator, ...updatedResponse };
        }
        return operator;
      });
      const data = this.formatData(updatedOperatorData);
      message.success("Trip Is Updated");
      this.setState({
        operatorTableData: data,
        operatorData: updatedOperatorData,
        showEditModal: false,
        newEditValues: {}
      });
    }
  };

  editData = async _id => {
    const { operatorData } = this.state;
    let stateObj = { showEditModal: true };
    const editData = operatorData.filter(data => data._id == _id)[0];
    if (editData.isIntraCity) {
      const stations = await this.fetchStations();
      const intraStations = stations.filter(
        station => station.cityId == editData.fromCityId
      );
      stateObj.stations = stations;
      stateObj.intraStations = intraStations;
    }
    stateObj.editData = editData;
    this.setState(stateObj);
  };
  copyData = value => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        message.success("Value copied: " + value);
      })
      .catch(err => {
        console.error("Error copying text: ", err);
      });
  };

  showLogs = _id => {
    let stateObj = { showLogModal: true };

    const { operatorData } = this.state;
    const logs = operatorData.filter(data => data._id == _id)[0]?.remark;

    if (logs) {
      const statusParts = logs.split(" ||| ");

      const updateRegex = /(.+?) is Updated from (.+?) to (.+?) by ([\w\s]+) at (.+)/;
      const addRegex = /([a-f0-9]{24}) added by ([\w\s]+) at (.+)/;
      const removeRegex = /([a-f0-9]{24}) removed by ([\w\s]+) at (.+)/;

      let parts = statusParts
        .map(status => {
          let match = status.match(updateRegex);
          if (match) {
            return {
              value: match[1], // Value being updated
              oldValue: match[2], // Old value
              newValue: match[3], // New value
              actor: match[4], // Actor (who made the change)
              time: match[5] // Time of the change
            };
          }

          match = status.match(addRegex);
          if (match) {
            return {
              value: match[1], // Value being added (e.g., asset ID or other identifier)
              oldValue: "", // No old value for "Added" logs
              newValue: "Added", // No new value for "Added" logs
              actor: match[2], // Actor (who made the addition)
              time: match[3] // Time of the addition
            };
          }

          match = status.match(removeRegex);
          if (match) {
            return {
              value: match[1], // Value being removed (e.g., asset ID or other identifier)
              oldValue: "", // No old value for "Removed" logs
              newValue: "Removed", // No new value for "Removed" logs
              actor: match[2], // Actor (who made the removal)
              time: match[3] // Time of the removal
            };
          }

          return null;
        })
        .filter(item => item !== null);

      const logsColoumn = [
        {
          title: "Value",
          dataIndex: "value",
          key: "value"
        },
        {
          title: "Old Value",
          dataIndex: "oldValue",
          key: "oldValue"
        },
        {
          title: "Updated Value",
          dataIndex: "newValue",
          key: "newValue"
        },
        {
          title: "Actor",
          dataIndex: "actor",
          key: "actor"
        },
        {
          title: "Time",
          dataIndex: "time",
          key: "time"
        }
      ];

      stateObj.logsColoumn = logsColoumn;
      stateObj.parts = parts;

      this.setState(stateObj);
    } else {
      message.error("No matching logs found");
    }
  };

  assignToOperator = async _id => {
    const { operatorData } = this.state;
    const updateBody = {
      assignedToOperator: true
    };
    const url = `/cabOperator/updateGroup`;

    const options = {
      method: "patch",
      data: { updateBody, _id: _id }
    };
    const response = await Fetch(url, options);

    if (Array.isArray(response) && response.length > 0) {
      const updatedOperatorData = operatorData.map(operator => {
        const updatedResponse = response.find(
          item => item._id === operator._id
        );

        if (updatedResponse) {
          return { ...operator, ...updatedResponse };
        }
        return operator;
      });
      const data = this.formatData(updatedOperatorData);

      this.setState({
        operatorTableData: data,
        operatorData: updatedOperatorData,
        showEditModal: false
      });
    }
  };

  searchData = response => {
    const data = this.formatData(response);
    this.setState({
      operatorTableData: data,
      operatorData: response
    });
  };

  render() {
    const {
      loading,
      contentLoading,
      operatorTableData,
      cities,
      editData = {},
      showEditModal,
      interValue = {},
      cabOperator,
      showCreateModal,
      intraStations = [],
      tripType,
      intraValue = {},
      cabOperators = [],
      assetTypeValues = [],
      assetType,
      showConfirmationModal,
      showErrorModal,
      showLogModal,
      parts,
      showErrowMessage = false,
      logsColoumn,
      newEditValues
    } = this.state;

    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div style={{ marginBottom: "10px" }}>
            <Search
              cabOperators={cabOperators}
              showLoader={this.showLoading}
              cities={cities}
              showCreateModal={this.showCreateTripModal}
              onSearch={this.searchData}
            />
          </div>
          <hr />
          <Table
            // scroll={{ y: "90vh" }}
            scroll={{ y: "70vh", x: "150%" }}
            loading={contentLoading}
            columns={columns}
            pagination={false}
            dataSource={operatorTableData}
            expandable={{
              expandedRowRender: record => this.handleExpandedRowRender(record),
              expandIcon: ({ expanded, onExpand, record }) => (
                <div
                  className={less.expandDetail}
                  onClick={() => onExpand(record, !expanded)}
                >
                  <img
                    src={
                      expanded
                        ? "https://d1flzashw70bti.cloudfront.net/original/images/tracer/caretdown.svg"
                        : "https://d1flzashw70bti.cloudfront.net/original/images/bookingdown.svg"
                    }
                    alt="show/hide"
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </div>
              ),
              onExpand: this.handleRowExpand,
              columnWidth: "5%"
            }}
          />
          {showEditModal ? (
            <Modal
              closable={false}
              visible={showEditModal}
              onCancel={() =>
                this.setState({
                  newEditValues: {},
                  showEditModal: false,
                  updateBody: {}
                })
              }
              onOk={this.updateGroup}
            >
              <div className={less.createModal}>
                <div className={less.heading}>
                  <span>Edit Trip</span>
                  <div>
                    <span>
                      {editData.fromCity.name} {editData.toCity.name}
                    </span>
                    <span>{moment(editData.startTime).format("h:mm a")}</span>
                  </div>
                </div>
                <div>
                  <span>Connecting Type*</span>
                  <Select
                    disabled
                    style={{ width: 450 }}
                    defaultValue={editData.isIntraCity ? "intra" : "inter"}
                    placeholder="Select"
                  >
                    <Option value="inter">Inter</Option>
                    <Option value="intra">Intra</Option>
                  </Select>
                </div>
                {!editData.isIntraCity ? (
                  <div className={less.typeSelector}>
                    <div>
                      <span>From City*</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={
                          newEditValues && newEditValues.edittedFromCity
                            ? newEditValues.edittedFromCity
                            : editData.fromCity.name
                        }
                        onChange={(value, data) =>
                          this.editValue("fromCityId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>To City*</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={
                          newEditValues && newEditValues.edittedToCity
                            ? newEditValues.edittedToCity
                            : editData.toCity.name
                        }
                        onChange={(value, data) =>
                          this.editValue("toCityId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div className={less.typeSelector}>
                    <div>
                      <span>City*</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={
                          newEditValues && newEditValues.edittedCity
                            ? newEditValues.edittedCity
                            : editData.fromCity.name
                        }
                        onChange={(value, data) =>
                          this.editValue("city", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>From Station*</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={
                          newEditValues &&
                          (newEditValues.edittedFromStation ||
                            newEditValues.edittedCity)
                            ? newEditValues.edittedFromStation
                            : editData.fromStation.name
                        }
                        onChange={(value, data) =>
                          this.editValue("fromStationId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {intraStations.map(station => (
                          <Option value={station.name} key={station._id}>
                            {station.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>To Station*</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={
                          newEditValues &&
                          (newEditValues.edittedToStation ||
                            newEditValues.edittedCity)
                            ? newEditValues.edittedToStation
                            : editData.toStation.name
                        }
                        onChange={(value, data) =>
                          this.editValue("toStationId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {intraStations.map(station => (
                          <Option value={station.name} key={station._id}>
                            {station.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
                <div>
                  <span>Date and Start Time*</span>
                  <DatePicker
                    disabled={editData.assignedToOperator}
                    style={{ width: 450 }}
                    showTime={{
                      format: "hh:mm A"
                    }}
                    value={moment(
                      newEditValues && newEditValues.edittedStartTime
                        ? newEditValues.edittedStartTime
                        : editData.startTime
                    )}
                    format="DD-MM-YYYY HH:mm"
                    showNow={false}
                    onOk={this.onEditOk}
                  />
                </div>
                <div className={less.edit}>
                  <div>
                    <span>Asset Type*</span>
                    <Select
                      style={{ width: 200 }}
                      value={
                        newEditValues && newEditValues.edittedAssetType
                          ? newEditValues.edittedAssetType
                          : editData.assetType
                      }
                      placeholder="Select"
                      onChange={value => this.editValue("assetType", value)}
                    >
                      {assetTypeValues.map(asset => (
                        <Option value={asset.name} key={asset.id}>
                          {asset.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>Trip Status*</span>
                    <Select
                      style={{ width: 200 }}
                      value={
                        newEditValues && newEditValues.edittedStatus
                          ? newEditValues.edittedStatus
                          : editData.status
                      }
                      placeholder="Select"
                      onChange={value => this.editValue("status", value)}
                    >
                      <Option value="ACTIVE">ACTIVE</Option>
                      <Option value="INACTIVE">INACTIVE</Option>
                    </Select>
                  </div>
                </div>

                <div>
                  <span>Operator*</span>
                  <Select
                    disabled={editData.assignedToOperator}
                    showSearch
                    style={{ width: 450 }}
                    onChange={(value, data) =>
                      this.editValue("cabOperatorId", value, data)
                    }
                    value={
                      newEditValues && newEditValues.edittedCabOperator
                        ? newEditValues.edittedCabOperator
                        : editData.cabOperator.name
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cabOperators.length > 0
                      ? cabOperators.map(operator => (
                          <Option value={operator.value} key={operator._id}>
                            {operator.value}
                          </Option>
                        ))
                      : null}
                  </Select>
                </div>
                {showErrowMessage ? (
                  <span className={less.error}> All Fields Are mandatory</span>
                ) : null}
              </div>
            </Modal>
          ) : null}

          <Modal
            title="Create a Cab Trip"
            visible={showCreateModal}
            onCancel={() =>
              this.setState({
                assetType: "",
                showCreateModal: false,
                showErrowMessage: false
              })
            }
            onOk={this.createCabGroup}
          >
            <div className={less.createModal}>
              <div>
                <span>Connecting Type*</span>
                <Select
                  style={{ width: 450 }}
                  value={tripType}
                  placeholder="Select"
                  onChange={value => this.handleChange("tripType", value)}
                >
                  <Option value="inter">Inter</Option>
                  <Option value="intra">Intra</Option>
                </Select>
              </div>
              {tripType == "inter" ? (
                <div className={less.typeSelector}>
                  <div>
                    <span>From City*</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      value={interValue.fromCity}
                      onChange={(value, data) =>
                        this.setInterCityValues("fromCityId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map(city => (
                        <Option value={city.name} key={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>To City*</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      value={interValue.toCity ? interValue.toCity : null}
                      onChange={(value, data) =>
                        this.setInterCityValues("toCityId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map(city => (
                        <Option value={city.name} key={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ) : (
                <div className={less.typeSelector}>
                  <div>
                    <span>City*</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      value={intraValue.city}
                      onChange={(value, data) =>
                        this.setIntraCityValues("city", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map(city => (
                        <Option value={city.name} key={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>From Station*</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      placeholder="Select"
                      value={intraValue.fromStationName}
                      onChange={(value, data) =>
                        this.setIntraCityValues("fromStationId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {intraStations.map(station => (
                        <Option value={station.name} key={station._id}>
                          {station.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>To Station*</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      placeholder="Select"
                      value={intraValue.toStationName}
                      onChange={(value, data) =>
                        this.setIntraCityValues("toStationId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {intraStations.map(station => (
                        <Option value={station.name} key={station._id}>
                          {station.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              <div>
                <span>Date and Start Time*</span>
                <DatePicker
                  style={{ width: 450 }}
                  showTime={{
                    format: "hh:mm A"
                  }}
                  disabledTime={current => {
                    if (!current) return {};

                    const isToday = current.isSame(moment(), "day");
                    const currentHour = moment().hour();
                    const currentMinute = moment().minute();

                    return {
                      disabledHours: () => {
                        if (isToday) {
                          let hoursToDisable = [];
                          for (let i = 0; i < currentHour; i++) {
                            hoursToDisable.push(i);
                          }
                          return hoursToDisable;
                        }
                        return [];
                      },
                      disabledMinutes: hour => {
                        if (hour === moment().hour()) {
                          let currentMinute = moment().minute();
                          let minutesToDisable = [];
                          for (let i = 0; i < currentMinute; i++) {
                            minutesToDisable.push(i);
                          }
                          return minutesToDisable;
                        }
                        return [];
                      }
                    };
                  }}
                  value={
                    tripType == "inter" && interValue.startTime
                      ? moment(interValue.startTime)
                      : tripType == "intra" && intraValue.startTime
                      ? moment(intraValue.startTime)
                      : null
                  }
                  disabledDate={currentDate => {
                    // Disable dates before today
                    return currentDate.isBefore(moment().startOf("day"), "day");
                  }}
                  format="DD-MM-YYYY HH:mm"
                  showNow={false}
                  onOk={this.onOk}
                />
              </div>
              <div>
                <span>Asset Type*</span>
                <Select
                  style={{ width: 450 }}
                  value={assetType}
                  placeholder="Select"
                  onChange={value => this.handleChange("assetType", value)}
                >
                  {assetTypeValues.map(asset => (
                    <Option value={asset.name} key={asset.id}>
                      {asset.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <span>Operator*</span>
                <Select
                  showSearch
                  style={{ width: 450 }}
                  onChange={(value, data) =>
                    this.handleChange("cabOperatorId", value, data)
                  }
                  value={cabOperator}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cabOperators.map(operator => (
                    <Option value={operator.value} key={operator._id}>
                      {operator.value}
                    </Option>
                  ))}
                </Select>
              </div>
              {showErrowMessage ? (
                <span className={less.error}> All Fields Are mandatory</span>
              ) : null}
            </div>
          </Modal>

          <Modal
            centered
            visible={showConfirmationModal}
            footer={null}
            // closable={false}
          >
            <div className={less.modalMessages}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/modal/check.svg"
                alt="ok"
              />
              <div>
                <span>Creation Successful</span>
                <span>Cab trip has been successfully created</span>
              </div>
              <button
                onClick={() =>
                  this.setState({
                    showConfirmationModal: false
                  })
                }
              >
                <span>OK</span>
              </button>
            </div>
          </Modal>
          <Modal visible={showErrorModal} centered footer={null}>
            <div className={less.modalMessages}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/modal/error.svg"
                alt="error"
              />
              <div>
                <span>Creation Failed</span>
                <span>Sorry, we couldn't create a cab trip</span>
              </div>
              <button onClick={() => this.setState({ showErrorModal: false })}>
                <span>OK</span>
              </button>
            </div>
          </Modal>

          {showLogModal ? (
            <Modal
              width={"70%"}
              title="Logs"
              centered
              visible={showLogModal}
              footer={null}
              onCancel={() => this.setState({ showLogModal: false })}
            >
              <Table
                loading={contentLoading}
                columns={logsColoumn}
                pagination={false}
                dataSource={parts}
              />
            </Modal>
          ) : null}
        </div>
      );
    }
  }
}

export default OperatorCabTrip;
