// const prodServerURL = "https://tracer.zingbus.com/v1";
// const devServerURL = "http://localhost:8030/v1";
const devServerURL = "https://testtracker.zingmobility.com/v1";

// const prodSocketURL = "https://tracer.zingbus.com/v1";
// const devSocketURL = "http://localhost:8030/";
const devSocketURL = "https://testtracker.zingmobility.com/";

export const googleKey = `AIzaSyAh9Ud5KvyyCsLC_BwK520oVbz8AVWZpgw`;
export const googleMapsApiKey = `AIzaSyAal1rA5biqFGR5LGDvI5ZU9-AYDoumcgY`;

export const busRoute = [
  { lat: 28.640034, lng: 77.207143 },
  { lat: 28.700354, lng: 77.227587 },
  { lat: 32.716541, lng: 74.744376 },
  { lat: 30.736363, lng: 76.778141 },
  { lat: 30.716541, lng: 76.744376 },
  { lat: 32.244174, lng: 77.190004 }
];

const { NODE_ENV } = process.env;

export let baseURL = devServerURL;
// export let baseURL = prodServerURL;

export let socketURL = devSocketURL;
// export let socketURL = prodSocketURL;

export const marketingCardsPriority = ["PLATINUM", "GOLD", "SILVER", "BRONZE"];
export const b2cURL = "https://staging2.zingmobility.com/";
export const prodServer = false;

export const prodTracerURL = devSocketURL;
// export const prodTracerURL = prodSocketURL;

export const testingAuthHeader = "jkscnkjasjc412532262@$#&^!cjac";
export const trackingTestFrameworkURL = "http://13.235.64.131:4001";
export const osmHeader = "jhciudhcuigefyv54545gdwcuhj0";
export const customerIssue = {
  marshalBehaviour: "5f13166a8c8d3c0ecd51dd03",
  healthEmergency: "5f13166a8c8d3c0ecd51dd04",
  contactPolice: "5f13166a8c8d3c0ecd51dd05"
};
export const majnuKaTilaId = [
  "5d84c3657f0d66618bd3e99b",
  "5da0617860670321b658f7d5"
];
export const abc = "akjdkl";
export const foodCarouselId = "614ff24afd87bb795bbfad14";

export const servicesToCheck = [
  "614c60cc54bdf537b48d6dac",
  "617156a36d4dcf170b15a95c",
  "629c56640f2248dab60c0d5b",
  "6139fda197794f575a53f5b9",
  "628c8411a780a730485fc934","5d84d13e1d32574aebb50881",
  "628c86fc3dee6cb21d1e11af"
];

export const mixPanelToken = "4dff8085c5fba594c0534ee6543b4a53";
export const isStage = true;
export const assuredRefundId = "64ad479de28a5cf7d53d31df";

export const gdsLoungeName = [
  {
    _id: "65a7c3577d684e852d0ba978",
    value: "Amar Hotel"
  },
  {
    _id: "65eee8ff1c72d0f2fbc16256",
    value: "Egmore"
  },
  {
    _id: "65a7c40d7d684e852d0ba97d",
    value: "Hebbal"
  }
];

export const whatsAppTemplates = [
  "pickup_update_lt_24_hours_v1",
  "not_boarded_v1",
  "tech_related_issues_v1",
  "rest_stop_change_v1",
  "route_change_v1",
  "charging_point_wifi_v1",
  "ac_not_working_v1",
  "breakdown_v2",
  "crew_misbehaviour_v1",
  "share_marshal_number_v1",
  "drop_point_update_v1",
  "delay_in_drop_v1",
  "delay_in_pickup_v1",
  "connecting_booking_modify_v1",
  "trip_assignment_2nd_leg_v1",
  "trip_assignment_1st_leg_v1",
  "booking_confirm_connecting_v1",
  "after_24_hours_cancellation_v3",
  "booking_modify_seat_change_v1",
  "booking_modify_postponed_v1",
  "booking_modify_preponed_v1",
  "before_24_hours_cancellation_v3",
  "trip_assignment_v7",
  "booking_confirmation_v8_1"
];

export const SMSTemplates = [
  "pickup_update_lt_24_hours_v1",
  "not_boarded_v1",
  "tech_related_issues_v1",
  "rest_stop_change_v1",
  "route_change_v1",
  "charging_point_wifi_v1",
  "ac_not_working_v1",
  "breakdown_v2",
  "crew_misbehaviour_v1",
  "share_marshal_number_v1",
  "drop_point_update_v1",
  "delay_in_drop_v1",
  "delay_in_pickup_v1",
  "connecting_booking_modify_v1",
  "trip_assignment_2nd_leg_v1",
  "trip_assignment_1st_leg_v1",
  "booking_confirm_connecting_v1",
  "after_24_hours_cancellation_v3",
  "booking_modify_seat_change_v1",
  "booking_modify_postponed_v1",
  "booking_modify_preponed_v1",
  "before_24_hours_cancellation_v3",
  "trip_assignment_v7",
  "booking_confirmation_v8_1"
];

export const issueExpireTime = 2 * 60 * 1000;

if (NODE_ENV === "development") {
  baseURL = devServerURL;
  socketURL = devSocketURL;
}

